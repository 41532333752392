import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import Element from 'element-ui'
import Vuex from 'vuex'
import store from './store/store.js'

Vue.use(Vuex)
Vue.use(Element, { size: 'small', zIndex: 3000 })

import htmlToPdf from '@/utils/topdf'
Vue.use(htmlToPdf)

import axios from 'axios'//引入axios
Vue.prototype.$axios = axios//把axios挂载到vue上

import router from './router/route'
Vue.use(router)

import VueQuillEditor from 'vue-quill-editor'

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { 默认全局 } */)


Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
