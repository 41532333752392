//此页面是存放api的url地址的
//主页地址
export const apiList = {
  login: "/login/",
  loginout: "/loginout/",
  indelist: "/article/",
  upload: "/uploadimg/",
  lunbolist: "/lunbo/",
  videolive: "/videolive/",
  photocollec: "/photocollec/",
  photos: "/photos/",
  usermini: "/usermini/",
  users: "/user/",
  resetpassw: "/resetpassw/"
}