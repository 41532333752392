import api from './axios.js'
import { apiList } from '@/api/api.js'

// 下面是简写的形式
// // getXXX 自定义的接口名字
// export const getXXX = (params) => api.get(`/apigb/v1/component`, { params})

// export const postXXX = (params) => api.post(`/apigb/v1/component/update-info`, params)

// export const postsave = (params) => api({
//   url: 'http://localhost/index.php/hourse/hourse_info/readuser', // 请求地址
//   method: 'get', // 请求方式
//   // data: params, // (一般post请求，我们习惯使用 data属性来传参)
//   params: params //(一般get请求，我们习惯使用params属性来传参）
// })


const alllist = {
  login: (params) => api({
    url: apiList.login, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  loginout: (params) => api({
    url: apiList.loginout, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),

  upload: (params) => api({
    url: apiList.upload, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),

  getarticlelist: (params) => api({
    url: apiList.indelist, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getarticlelistbyid: (params, id) => api({
    url: apiList.indelist + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postarticlelist: (params) => api({
    url: apiList.indelist, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataarticlelist: (params, id) => api({
    url: apiList.indelist + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletearticlelist: (params, id) => api({
    url: apiList.indelist + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //轮播图
  getlunbolist: (params) => api({
    url: apiList.lunbolist, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getlunbolistbyid: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postlunbolist: (params) => api({
    url: apiList.lunbolist, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatalunbolist: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletelunbolist: (params, id) => api({
    url: apiList.lunbolist + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //直播
  getvideolivelist: (params) => api({
    url: apiList.videolive, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getvideolivelistbyid: (params, id) => api({
    url: apiList.videolive + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postvideolivelist: (params) => api({
    url: apiList.videolive, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatavideolivelist: (params, id) => api({
    url: apiList.videolive + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletevideolivelist: (params, id) => api({
    url: apiList.videolive + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //相册集合
  getphotocolleclist: (params) => api({
    url: apiList.photocollec, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getphotocollecbyid: (params, id) => api({
    url: apiList.photocollec + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postphotocolleclist: (params) => api({
    url: apiList.photocollec, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataphotocolleclist: (params, id) => api({
    url: apiList.photocollec + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletephotocolleclist: (params, id) => api({
    url: apiList.photocollec + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //相册照片视频
  getphotolist: (params) => api({
    url: apiList.photos, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getphotobyid: (params, id) => api({
    url: apiList.photos + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postphotolist: (params) => api({
    url: apiList.photos, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updataphotolist: (params, id) => api({
    url: apiList.photos + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deletephotolist: (params, id) => api({
    url: apiList.photos + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //小程序用户
  getuserminilist: (params) => api({
    url: apiList.usermini, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getuserminibyid: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postuserminilist: (params) => api({
    url: apiList.usermini, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatauserminilist: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteuserminilist: (params, id) => api({
    url: apiList.usermini + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //管理端用户
  getuserlist: (params) => api({
    url: apiList.users, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  getuserbyid: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'get', // 请求方式
    params: params,
  }),
  postuserlist: (params) => api({
    url: apiList.users, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
  updatauserlist: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'put', // 请求方式
    data: params,
  }),
  deleteuserlist: (params, id) => api({
    url: apiList.users + id, // 请求地址
    method: 'delete', // 请求方式
    data: params,
  }),
  //修改密码
  postmypassw: (params) => api({
    url: apiList.resetpassw, // 请求地址
    method: 'post', // 请求方式
    data: params,
  }),
}

export default alllist