<template>
  <el-card class="order-container">
    <template #header>
      <div class="header">
        <el-input
          style="width: 200px; margin-right: 10px"
          :placeholder="'请输入' + searchtitle"
          v-model="searchinfo"
          @change="handleinput"
          clearable
        />
        <!-- <el-button type="primary" size="small" icon="el-icon-edit">修改订单</el-button> -->
        <el-button type="primary" @click="handleConfig()">搜索</el-button>
        <!-- <el-button type="primary" :icon="HomeFilled" @click="handleSend()"
          >出库</el-button
        > -->
        <el-button type="danger" @click="handledeleteall()">删除</el-button>
        <el-button type="success" @click="handleAdd()">新增</el-button>
      </div>
    </template>
    <el-table
      v-loading="loading"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :prop="item.propname"
        :label="item.name"
        v-for="item in labellist"
        :key="item.name"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="item.propname == 'imageurl'">
            <img style="height: 100px" :src="scope.row[item.propname]" alt="" />
          </div>
          <div v-else-if="item.propname == 'is_start'">
            <div :class="scope.row.is_start == 0 ? 'red' : 'green'">
              {{ scope.row.is_start == 0 ? "未上架" : "已上架" }}
            </div>
          </div>
          <div v-else-if="item.propname == 'live_status'">
            <div :class="scope.row.live_status == 1 ? 'green' : 'red'">
              {{
                scope.row.live_status == 0
                  ? "未直播"
                  : scope.row.live_status == 1
                  ? "正在直播"
                  : "直播结束"
              }}
            </div>
          </div>
          <div
            v-else
            style="height: 100px；  word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 超出几行省略 */
            overflow: hidden;
          "
          >
            {{ scope.row[item.propname] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <!-- <el-button
            :type="scope.row.is_start == 0 ? 'success' : 'danger'"
            style="margin-left: 2px"
            @click="handleuplunbo(scope.row)"
            >{{ scope.row.is_start == 0 ? "上架" : "下架" }}</el-button
          > -->
          <el-button
            type="danger"
            style="margin-left: 2px"
            @click="handledelete(scope.row)"
            >删除</el-button
          >
          <el-button
            type="warning"
            style="margin-left: 2px"
            @click="handleedit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加/编辑直播" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="videoliveRef">
        <el-form-item
          prop="title"
          label="标题"
          required
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          required
          label="封面"
          prop="imageurl"
          :label-width="formLabelWidth"
        >
          <el-upload
            class="upload-demo"
            drag
            name="filename"
            :action="uploadImg"
            :on-success="successupload"
            :limit="1"
            :on-remove="removeupload"
          >
            <div v-if="form.imageurl == null">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </div>
            <div v-else>
              <img style="height: 180px" :src="form.imageurl" alt="" />
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          required
          label="直播时间"
          prop="start_time"
          :label-width="formLabelWidth"
        >
          <el-date-picker
            v-model="form.start_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          required
          label="直播人"
          prop="videolive_name"
          :label-width="formLabelWidth"
        >
          <el-select v-model="form.videolive_name" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handlecancle()">取 消</el-button>
        <el-button type="primary" @click="submitAdd((id = null))"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="changePage"
    />
  </el-card>
</template>

<script>
import alllist from "@/utils/request"
// import addoreditVue from '@/components/addorEdit.vue'
import { uploadImgServer } from '@/utils/info'
export default {
  components: {
    // addoreditVue
  },
  data () {
    return {
      loading: false,
      tableData: [], // 数据列表
      multipleSelection: [], // 选中项
      total: 0, // 总条数
      currentPage: 1, // 当前页
      pageSize: 10, // 分页大小
      searchinfo: '', // 搜索内容
      searchtitle: '标题', //搜索框搜索内容
      selectinfo: '', // 下拉框内容
      // 筛选项默认值
      getdatainfo: 'articlelist',
      deletearr: [],
      dialogFormVisible: false,
      uploadImg: uploadImgServer,
      form: {
        imageurl: null,
        title: '',
        start_time: "",
        videolive_name: ''
      },
      options: [],
      formLabelWidth: '120px',
      labellist: [
        {
          propname: "id",
          name: 'ID'
        },
        {
          propname: "imageurl",
          name: '封面'
        },
        {
          propname: "title",
          name: '直播标题'
        },
        {
          propname: "start_time",
          name: '开始时间'
        },
        {
          propname: "live_status",
          name: '直播状态'
        },
        {
          propname: "videilive_url",
          name: '推流地址'
        },
        {
          propname: "videolive_name",
          name: '直播人'
        },
        {
          propname: "creat_time",
          name: '时间'
        }
      ],
      rules: {
        imageurl: [
          { required: 'true', message: '请上传封面', trigger: ['change'] }
        ],
        title: [
          { required: 'true', message: '请填写标题', trigger: ['change'] }
        ],
        start_time: [
          { required: 'true', message: '请选择时间', trigger: ['change'] }
        ],
        videolive_name: [
          { required: 'true', message: '请选择直播人', trigger: ['change'] }
        ]
      },
    }
  },
  created () {
    this.statusnum = 0
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取列表方法
    getList () {
      this.loading = true
      let data = {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        searchinfo: this.searchinfo
      }
      alllist.getvideolivelist(data).then((res) => {
        console.log(res)
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    getadmin () {
      let data = {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        searchinfo: this.searchinfo,
        is_admin: ''
      }
      alllist.getuserminilist(data).then((res) => {
        let r = res.data.data.map(item => {
          return {
            value: item.nickname,
            label: item.nickname
          }
        })
        this.options = r
      })
    },
    handleAdd () {
      this.dialogFormVisible = true
      this.getadmin()
    },
    changestatus () {
      this.getList()
    },
    // 触发过滤项方法
    handleinput () {
      this.currentPage = 1
      // getOrderList()
      this.getList()
    },
    // checkbox 选择项
    handleOption (val) {
      console.log(val)
      this.selectinfo = val
      this.getList()
    },
    // checkbox 选择项
    handleSelectionChange (val) {
      let arr = []
      for (var i = 0; i < val.length; i++) {
        arr.push(val[i].id)
      }
      this.deletearr = arr
    },
    // 翻页方法
    changePage (val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    handleedit (row) {
      this.form = row
      this.dialogFormVisible = true
      this.getadmin()
    },
    handlecancle () {
      this.dialogFormVisible = false
      this.$refs.videoliveRef.resetFields()
      this.form = {
        imageurl: null,
        title: '',
        start_time: "",
        videolive_name: ''
      }
    },
    successupload (e) {
      console.log(e)
      this.form.imageurl = e.url
    },
    removeupload () {
      this.form.imageurl = null
    },
    handleuplunbo (row) {
      let data = row
      if (data.is_start == 0) {
        data.is_start = 1
      } else {
        data.is_start = 0
      }
      alllist.updatavideolivelist(data, data.id).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: data.is_start == 0 ? '下架成功！' : '上架成功！'
        })
      })
    },
    submitAdd () {
      this.$refs.videoliveRef.validate((vaild) => {
        if (vaild) {
          if (this.form.id) {
            alllist.updatavideolivelist(this.form, this.form.id).then((res) => {
              if (res.data != null) {
                this.dialogFormVisible = false
                let this_ = this
                this_.$notify.success({
                  title: '成功！',
                  message: "修改成功！",
                  duration: 500,
                  onClose () {
                    this_.$router.push({
                      path: '/videolive'
                    })
                    this_.handlecancle()
                    this_.getList()
                  }
                })
              }
            })
          } else {
            alllist.postvideolivelist(this.form).then((res) => {
              if (res.data != null) {
                this.dialogFormVisible = false
                let this_ = this
                this_.$notify.success({
                  title: '成功！',
                  message: "添加成功！",
                  duration: 500,
                  onClose () {
                    this_.$router.push({
                      path: '/videolive'
                    })
                    this_.handlecancle()
                    this_.getList()
                  }
                })
              }
            })
          }
        }
      })
    },
    handledeleteall () {
      if (this.deletearr.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择！'
        })
      } else {
        this.$alert('确认要删除吗？', '删除', {
          confirmButtonText: '确定',
          callback: action => {
            if (action == 'confirm') {
              for (var i = 0; i < this.deletearr.length; i++) {
                let data = {}
                alllist.deletevideolivelist(data, this.deletearr[i]).then(() => {
                  this.getList()
                })
              }
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
            }
          }
        })
      }
    },
    handledelete (row) {
      this.$alert('确认要删除吗？', '删除', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            let data = {}
            alllist.deletevideolivelist(data, row.id).then(() => {
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
              this.getList()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.red {
  color: red;
}
.green {
  color: green;
}
</style>