<template>
  <div class="layout">
    <el-container v-if="showMenu" class="container">
      <el-aside class="aside">
        <div class="head">
          <div>
            <!-- <img src="//s.weituibao.com/1582958061265/mlogo.png" alt="logo" /> -->
            <span>Admin管理系统</span>
          </div>
        </div>
        <div class="line" />
        <el-menu
          background-color="#222832"
          text-color="#fff"
          :router="true"
          :default-openeds="defaultOpen"
          :default-active="currentPath"
        >
          <el-submenu index="1">
            <template #title>
              <span>文章轮播管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/"
                ><i class="el-icon-menu"></i>文章管理</el-menu-item
              >
              <el-menu-item index="/lunbo"
                ><i class="el-icon-menu"></i>轮播图管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template #title>
              <span>媒体管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/videolive"
                ><i class="el-icon-menu"></i>直播管理</el-menu-item
              >
              <el-menu-item index="/photocollec"
                ><i class="el-icon-menu"></i>相册集合管理</el-menu-item
              >
              <el-menu-item index="/photo"
                ><i class="el-icon-menu"></i>照片管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template #title>
              <span>用户管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/usermini"
                ><i class="el-icon-menu"></i>小程序用户管理</el-menu-item
              >
              <el-menu-item index="/users"
                ><i class="el-icon-menu"></i>管理端用户管理</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template #title>
              <span>系统管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/resetpassw"
                ><i class="el-icon-menu"></i>修改密码</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container class="content">
        <Header @loginout="loginout" />
        <div class="main">
          <router-view />
        </div>
        <Footer />
      </el-container>
    </el-container>
    <el-container v-else class="container">
      <router-view />
    </el-container>
  </div>
</template>

<script>
const noMenu = ['/login']
import Header from '@/components/headerPage.vue'
import Footer from '@/components/footerPage.vue'
import { pathMap, localGet } from './utils/info'
export default {
  name: 'App',
  data () {
    return {
      showMenu: true,
      defaultOpen: ['1', '2', '3', '4'],
      currentPath: '/',
      routerurl: this.$route.path
    }
  },
  components: {
    Header, Footer
  },
  created () {
    this.routemethod()
    setInterval(() => {
      let token = localGet('token')
      if (!token) {
        this.showMenu = false
      }
    }, 100)
  },
  methods: {
    routemethod () {
      let routeinfo = this.$route.path
      if (routeinfo == "/login") {
        this.currentPath = routeinfo
        document.title = pathMap[this.$route.name]
        this.showMenu = !noMenu.includes(routeinfo)
      } else {
        this.currentPath = routeinfo
      }
      console.log("routeinfo", routeinfo)
    },
    loginout () {
      this.showMenu = false
    }
  }
}
</script>

<style scoped>
.layout {
  min-height: 100vh;
  background-color: #ffffff;
}
.container {
  height: 100vh;
}
.aside {
  width: 220px !important;
  background-color: #222832;
}
.head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.head > div {
  display: flex;
  align-items: center;
}

.head img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.head span {
  font-size: 20px;
  color: #ffffff;
}
.line {
  border-top: 1px solid hsla(0, 0%, 100%, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.content {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}
.main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 10px;
}
</style>

<style>
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.el-menu {
  border-right: none !important;
}
.el-submenu {
  border-top: 1px solid hsla(0, 0%, 100%, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.el-submenu:first-child {
  border-top: none;
}
.el-submenu [class^="el-icon-"] {
  vertical-align: -1px !important;
}
a {
  color: #409eff;
  text-decoration: none;
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}
.el-popper__arrow {
  display: none;
}
</style>
