<template>
  <el-card class="account-container">
    <el-form
      :model="passForm"
      :rules="rules"
      ref="passRef"
      label-width="80px"
      label-position="right"
      class="demo-ruleForm"
    >
      <el-form-item label="登录名：" prop="loginName">
        <el-input style="width: 200px" v-model="passForm.loginName"></el-input>
      </el-form-item>
      <el-form-item label="原密码：" prop="oldpass">
        <el-input style="width: 200px" v-model="passForm.oldpass"></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newpass">
        <el-input style="width: 200px" v-model="passForm.newpass"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="submitPass">确认修改</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { localGet, localRemove } from '@/utils/info'
import alllist from '@/utils/request'
export default {
  data () {
    return {
      passForm: {
        loginName: '',
        oldpass: '',
        newpass: ''
      },
      rules: {
        loginName: [
          { required: 'true', message: '登录名不能为空', trigger: ['change'] }
        ],
        oldpass: [
          { required: 'true', message: '原密码不能为空', trigger: ['change'] }
        ],
        newpass: [
          { required: 'true', message: '新密码不能为空', trigger: ['change'] }
        ]
      },
    }
  },
  mounted () {
    this.passForm.loginName = localGet('userinfo').username
  },
  methods: {
    submitPass () {
      this.$refs.passRef.validate((vaild) => {
        if (vaild) {
          alllist.postmypassw(this.passForm).then((res) => {
            if (res.data != null) {
              if (res.data.code == 4033) {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              } else {
                this.$message({
                  type: 'success',
                  message: '修改成功！'
                })
                localRemove('token')
                localRemove('userinfo')
                this.$router.push({
                  path: '/login'
                })
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.account-container {
  margin-bottom: 20px;
}
</style>