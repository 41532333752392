import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {

    //这里放全局参数
    name: "111"
  },

  mutations: {

    //这里是set方法

  },

  getters: {        //这里是get方法,并且每次打开浏览器优先执行该方法，获取所有的状态   


  },

  actions: {

    // 处理state的方法体

  },

  modules: {

    //这里是我自己理解的是为了给全局变量分组，所以需要写提前声明其他store文件，然后引入这里

  }

})
