<template>
  <div class="add">
    <el-card class="add-container">
      <el-form
        :model="formInfo"
        :rules="rules"
        ref="articleRef"
        label-width="100px"
        class="goodForm"
      >
        <div v-for="item in labellist" :key="item.name">
          <el-form-item
            :label="item.name"
            :prop="item.labelname"
            v-if="item.type == 0"
            required
          >
            <el-input
              style="width: 360px"
              v-model="formInfo[item.labelname]"
              :placeholder="'请输入' + item.name"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="item.name"
            :prop="item.labelname"
            v-if="item.type == 1"
            required
          >
            <el-select
              style="width: 360px"
              v-model="formInfo[item.labelname]"
              :placeholder="'请选择' + item.name"
              @change="selectchange($event, item)"
            >
              <el-option
                v-for="item2 in item.options"
                :key="item2.value"
                :label="item2.label"
                :value="item2.label"
                required
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="item.name"
            :prop="item.labelname"
            v-if="item.type == 2"
          >
            <el-radio-group v-model="formInfo[item.labelname]">
              <el-radio label="0">上架</el-radio>
              <el-radio label="1">下架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            required
            :label="item.name"
            :prop="item.labelname"
            v-if="item.type == 3"
          >
            <el-upload
              class="upload-demo"
              drag
              name="filename"
              :action="uploadImg"
              :on-success="successupload"
              :limit="1"
              :on-remove="removeupload"
            >
              <div v-if="formInfo.imageurl == null">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </div>
              <div v-else>
                <img style="height: 180px" :src="formInfo.imageurl" alt="" />
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            required
            :label="item.name"
            v-if="item.type == 4"
            :prop="item.labelname"
          >
            <div ref="editor"></div>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitAdd()">{{
            id ? "立即修改" : "立即创建"
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
let instance
import WangEditor from 'wangeditor'
import alllist from '@/utils/request'
import { localGet, uploadImgServer } from '@/utils/info'
export default {
  data () {
    return {
      id: 0,
      uploadImg: uploadImgServer,
      formInfo: {
        title: '',
        createbyname: localGet('userinfo').username,
        imageurl: null,
        articlecontent: ''
      },
      // 0->输入框 1->下拉列表 2->单选 3->图片上传 4->文本
      labellist: [
        {
          labelname: 'title',
          name: '文章标题',
          type: 0
        },
        {
          labelname: 'imageurl',
          name: '图片',
          type: 3,
        },
        {
          labelname: 'articlecontent',
          name: '内容',
          type: 4
        }
      ],
      rules: {
        title: [
          { required: 'true', message: '请填写标题', trigger: ['change'] }
        ],
        imageurl: [
          { required: 'true', message: '请上传图片', trigger: ['change'] }
        ],
        articlecontent: [
          { required: 'true', message: '请填写内容', trigger: ['change'] }
        ],
      },

      token: localGet('token') || ''
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getlistbyid(this.$route.query.id)
      this.id = 1
    }
    this.edit()
  },
  methods: {
    selectchange (e, item) {
      console.log(e, item)
      this.formInfo[item.labelname] = e

    },
    successupload (e) {
      console.log(e)
      this.formInfo.imageurl = e.url
    },
    removeupload () {
      this.formInfo.imageurl = null
    },
    submitAdd () {
      let id = this.$route.query.id
      this.$refs.articleRef.validate((vaild) => {
        if (vaild) {
          if (id) {
            alllist.updataarticlelist(this.formInfo, id).then((res) => {
              if (res.data != null) {
                let this_ = this
                this_.$notify.success({
                  title: '成功！',
                  message: "修改成功！",
                  duration: 500,
                  onClose () {
                    this_.$router.push({
                      path: '/index'
                    })
                  }
                })
              }
            })
          } else {
            alllist.postarticlelist(this.formInfo).then((res) => {
              if (res.data != null) {
                let this_ = this
                this_.$notify.success({
                  title: '成功！',
                  message: "添加成功！",
                  duration: 500,
                  onClose () {
                    this_.$router.push({
                      path: '/index'
                    })
                  }
                })
              }
            })
          }
        }
      })
    },
    getlistbyid (id) {
      let data = {}
      alllist.getarticlelistbyid(data, id).then((res) => {
        this.formInfo = res.data
        instance.txt.html(res.data.articlecontent)
      })
    },
    edit () {
      instance = new WangEditor(this.$refs.editor)
      instance.config.showLinkImg = false
      instance.config.showLinkImgAlt = false
      instance.config.showLinkImgHref = false
      instance.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
      instance.config.uploadFileName = 'filename'

      // 图片返回格式不同，需要自定义返回格式
      instance.config.uploadImgHooks = {
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          if (result) {
            insertImgFn(result.url)
            // console.log(result)
            // result.data.forEach(item => insertImgFn(item))
          }
        }
      }
      instance.config.uploadImgServer = uploadImgServer
      let self = this
      Object.assign(instance.config, {
        onchange () {
          console.log('change')
          self.formInfo.articlecontent = instance.txt.html()
        },
      })
      instance.config.uploadImgMaxLength = 1
      // 限制上传图片格式
      instance.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp']

      instance.create()
    }
  }
}
</script>

<style scoped>
.add {
  display: flex;
}
.add-container {
  flex: 1;
  height: 100%;
}
.avatar-uploader {
  width: 100px;
  height: 100px;
  color: #ddd;
  font-size: 30px;
}
.avatar-uploader-icon {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  padding: 32px 17px;
}
</style>