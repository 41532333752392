<template>
  <div class="login-body">
    <div class="login-container">
      <div class="head">
        <!-- <img
          class="logo"
          src="https://s.yezgea02.com/1582958061265/mlogo.png"
        /> -->
        <div class="name">
          <div class="title">后台管理系统</div>
          <!-- <div class="tips">Vue3.0 后台管理系统</div> -->
        </div>
      </div>
      <el-form
        label-position="top"
        :rules="rules"
        :model="ruleForm"
        ref="loginForm"
        class="login-form"
      >
        <el-form-item label="账号" prop="username">
          <el-input
            type="text"
            v-model.trim="ruleForm.username"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model.trim="ruleForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="color: #333">登录表示您已同意<a>《服务条款》</a></div>
          <el-button style="width: 100%" type="primary" @click="submitForm"
            >立即登录</el-button
          >
          <el-checkbox v-model="checked" @change="!checked"
            >下次自动登录</el-checkbox
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import alllist from "@/utils/request"
// import axios from '@/utils/axios'
// import md5 from 'js-md5'
import { localSet } from '@/utils/info'

export default {
  data () {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      checked: true,
      rules: {
        username: [
          { required: 'true', message: '账户不能为空', trigger: 'blur' }
        ],
        password: [
          { required: 'true', message: '密码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {

  },
  methods: {
    formatTime (seconds) {
      var minutes = Math.floor(seconds / 60)
      var second = Math.floor(seconds - (minutes * 60))
      return (minutes < 10 ? '0' + minutes : minutes) + ':' + (second < 10 ? '0' + second : second)
      // return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2)
    },
    submitForm () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let data = {
            username: this.ruleForm.username || '',
            password: this.ruleForm.password
          }
          alllist.login(data).then((res) => {
            console.log(res)
            if (res.data.code == 200) {
              localSet('token', res.data.token)
              localSet('userinfo', res.data)
              this.$notify.success({
                title: '成功！',
                message: "登录成功！",
                duration: 1000,
                onClose () {
                  window.location.href = '/'
                }
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.loginForm.value.resetFields()
    }
  }
}
</script>
<style scoped>
.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  background-image: url("../assets/bg.jpg");
}
.login-container {
  width: 420px;
  height: 400px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 21px 41px 0px rgba(0, 0, 0, 0.2);
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 20px 0;
}
.head img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.head .title {
  font-size: 28px;
  color: #1baeae;
  font-weight: bold;
}
.head .tips {
  font-size: 12px;
  color: #999;
}
.login-form {
  width: 70%;
  margin: 0 auto;
}
.login-form >>> .el-form--label-top .el-form-item__label {
  padding: 0;
}
.login-form >>> .el-form-item {
  margin-bottom: 10px;
}
</style>