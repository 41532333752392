import Vue from 'vue'
import VueRouter from 'vue-router'
import { localGet } from '../utils/info'

// // 0. 如果使用模块化机制编程，导入Vue和VueRouter，要调用 Vue.use(VueRouter)
Vue.use(VueRouter)

import indexpage from "../views/indexPage.vue"
import login from "../login/loginPage.vue"
import addoredit from "../views/addorEdit.vue"
import lunbo from "../views/lunboPage.vue"
import videolive from "../views/videolivePage.vue"
import photocollec from "../views/photocollecPage.vue"
import photo from "../views/photosPage.vue"
import usermini from "../views/userminiPage.vue"
import users from "../views/usersPage.vue"
import resetpassw from "../views/resetPassword.vue"

export const routes = [
  { path: '/', name: "index", component: indexpage },
  { path: '/index', component: indexpage },
  { path: '/login', name: "login", component: login },
  { path: '/addoredit', name: "addoredit", component: addoredit },
  { path: '/lunbo', name: "lunbo", component: lunbo },
  { path: '/videolive', name: "videolive", component: videolive },
  { path: '/photocollec', name: "photocollec", component: photocollec },
  { path: '/photo', name: "photo", component: photo },
  { path: '/usermini', name: "usermini", component: usermini },
  { path: '/users', name: "users", component: users },
  { path: '/resetpassw', name: "resetpassw", component: resetpassw }
]

// // 3. 创建 router 实例，然后传 `routes` 配置

const router = new VueRouter({
  mode: "hash",
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    // 如果路径是 /login 则正常执行
    console.log("to", from)
    next()
  } else {
    console.log(from)
    // 如果不是 /login，判断是否有 token
    if (!localGet('token')) {
      // 如果没有，则跳至登录页面
      next({ path: '/login' })
    } else {
      // 否则继续执行
      next()
    }
  }
})
export default router 
