<template>
  <div class="header">
    <div class="left">
      <i class="back el-icon-back" v-if="hasBack" @click="back"></i>
      <span style="font-size: 20px">{{ name }}</span>
    </div>
    <div class="right">
      <el-popover
        placement="bottom"
        :width="320"
        trigger="click"
        popper-class="popper-user-box"
      >
        <template #reference>
          <div class="author">
            <i class="icon el-icon-s-custom" />
            {{ (userInfo && userInfo.loginUserName) || "" }}
            <i class="el-icon-caret-bottom" />
          </div>
        </template>
        <div class="nickname">
          <p>登录名：{{ (userInfo && userInfo.loginUserName) || "" }}</p>
          <p>昵称：{{ (userInfo && userInfo.loginUserName) || "" }}</p>
          <el-tag size="small" effect="dark" class="logout" @click="logout"
            >退出</el-tag
          >
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { pathMap, localRemove, localGet } from '@/utils/info'
import alllist from '@/utils/request'
export default {
  data () {
    return {
      name: '主页',
      userInfo: {
        loginUserName: "鹿儿",
        nickName: "陆"
      }, // 用户信息变量
      hasBack: false, // 是否展示返回icon
    }
  },
  mounted () {
    setInterval(() => {
      this.userInfo.loginUserName = localGet('userinfo').username

      this.name = pathMap[this.$route.name]
      if (this.$route.path == '/addoredit') {
        this.hasBack = true
      } else {
        this.hasBack = false
      }
    }, 100)
  },
  methods: {
    // 获取用户信息
    getUserInfo () {
      // const userInfo = axios.get('/adminUser/profile')
      // state.userInfo = userInfo
    },
    back () {
      this.$router.push({
        path: '/index'
      })
    },
    // 退出登录
    logout () {
      let data = {}
      alllist.loginout(data).then((res) => {
        console.log(res)
        localRemove('token')
        localRemove('userinfo')
        this.$emit('loginout', 1)
        this.$router.push({
          path: '/login'
        })
      })
      // this.delete('/logout').then(() => {
      //   // 退出之后，将本地保存的 token  清理掉
      //   localRemove('token')
      //   // 回到登录页
      //   router.push({ path: '/login' })
      // })
    }

  }
}
</script>

<style scoped>
.header {
  height: 50px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.header .left .back {
  border: 1px solid #e9e9e9;
  padding: 5px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}
.right > div > .icon {
  font-size: 18px;
  margin-right: 6px;
}
.author {
  margin-left: 10px;
  cursor: pointer;
}
</style>

<style>
.popper-user-box {
  background: url("https://s.yezgea02.com/lingling-h5/static/account-banner-bg.png")
    50% 50% no-repeat !important;
  background-size: cover !important;
  border-radius: 0 !important;
}
.popper-user-box .nickname {
  position: relative;
  color: #ffffff;
}
.popper-user-box .nickname .logout {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
</style>