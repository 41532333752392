export function localGet (key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

export function localSet (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove (key) {
  window.localStorage.removeItem(key)
}

// 判断内容是否含有表情字符，现有数据库不支持。
export function hasEmoji (str = '') {
  const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
  return str.match(reg) && str.match(reg).length
}

// 单张图片上传
// export const uploadImgServer = 'http://127.0.0.1:8000/uploadimg/'
// 多张图片上传
// export const uploadImgsServer = 'http://127.0.0.1:8000/uploadimg/'

// 单张图片上传
export const uploadImgServer = 'https://yanxue.lovt.com.cn/uploadimg/'
// 多张图片上传
export const uploadImgsServer = 'https://yanxue.lovt.com.cn/uploadimg/'

// export const uploadImgsServer = 'http://backend-api-02.newbee.ltd/manage-api/v1/upload/files'

export const pathMap = {
  login: '登录',
  index: "主页",
  addoredit: '添加编辑商品',
  lunbo: '轮播图管理',
  videolive: '直播管理',
  photocollec: '相册集合管理',
  photo: '图片视频管理',
  usermini: '小程序用户管理',
  users: '用户管理',
  resetpassw: '修改密码'
}