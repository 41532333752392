
import axios from 'axios'//首先引入axios
import { Notification } from 'element-ui'
import { localGet, localRemove } from '@/utils/info'
import router from '@/router/route'


//创建一个实例
// const url = "http://127.0.0.1:8000"
const url = "https://yanxue.lovt.com.cn"
console.log(localGet('token'))
const api = axios.create({
  baseURL: url, // 所有请求的公共地址部分
  timeout: 3000,// 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset:utf-8;',
    'Authorization': localGet('token') || ''
  },
})
//request拦截器
// 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
api.interceptors.request.use(config => {
  // config 请求的所有信息
  // console.log(config);
  return config // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
}, err => {
  // 请求发生错误时的相关处理 抛出错误
  Promise.reject(err)
})

//response拦截器
api.interceptors.response.use(res => {
  // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
  // res 是所有相应的信息
  const code = res.data.code
  const msg = res.data.msg
  if (code == 403) {
    Notification.error({
      title: '错误',
      message: msg
    })
    router.push({
      path: '/login'
    })
    localRemove('token')
  } else {
    return Promise.resolve(res)
  }

}, err => {
  // 服务器响应发生错误时的处理
  Promise.reject(err)
})

export default api
